<template>
  <div v-if="isPermission">
    <CCard>
      <CCardBody>
        <div class="d-flex">
          <!-- <div class="mr-auto text-muted" @click="goback">
            <i class="fas fa-lg fa-chevron-left"></i>
          </div> -->
          <div class="col-md-2 col-sm-3 col-3  text-right">
              <CButton id="memberDetailBackButton" block color="light" @click="goback" class="align-items-center">
                <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
              </CButton>
          </div>
          <div class="col-md-10 col-sm-9 col-9 row justify-content-end">
              <router-link
              v-if="isEditMember"
              class="text-success"
              style="text-decoration: none;"
              :to="`/member/${objectId}/editmember`"
              id="memberDetailEditLink"
            >
            <i class="fi fi-rr-edit"></i>
            </router-link>
          </div>
        </div>
        <div class="text-center">
          <img
            v-if="data.avatar"
            class="img-fluid rounded-circle"
            width="15%"
            :src="data.avatar"
            id="memberDetailAvatar"
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/149/149071.png';"
          />
          <div v-else>
            <div class="row">
              <div class="col-sm-2 mx-auto">
                <div class="circle" id="memberDetailNoAvatar">
                  <h3>{{ catIcon(firstname) }}</h3>
                </div>
              </div>
            </div>
          </div>
          <h1 class="font-weight-normal mt-3" id="memberDetailName">
            {{ firstname }} {{ lastname }}
            <img
              v-if="isMember === 'line'"
              :src="imgSrc"
              width="3%"
              class="img-fluid"
              id="memberDetailLineIcon"
            />
          </h1>
          <h4 class="text-muted" id="memberDetailPoint">
            {{ convertPoint(data.point) }} {{ $t('pointtxt') }}
          </h4>
        </div>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="border-right text-success">
            <div class="d-flex">
              <div class="mr-auto">
                <h5 id="memberDetailTotalValue">{{ $t('totaValue') }}</h5>
              </div>
              <div>
                <h5 class="text-dark" id="memberDetailBillCount">{{ bill }} {{ $t('bill') }}</h5>
              </div>
            </div>

            <h1 class="font-weight-normal text-center" id="memberDetailGrandTotal">
              {{ convertCurrency(grandTotal) }}
            </h1>
          </CCol>
          <CCol class="text-warning">
            <h5 class="" id="memberDetailAvgBill">{{ $t('averageBill') }}</h5>
            <h1 class="font-weight-normal text-center" id="memberDetailAvgBillValue">{{ avgBill }}</h1>
          </CCol>
          <!-- <CCol class="border-left text-danger">
            <div class="d-flex">
              <div class="mr-auto">
                <h5 class="">บิลที่เปิดอยู่</h5>
              </div>
              <div>
                <h5 class="text-black">0 บิล</h5>
              </div>
            </div>
            <h1 class="font-weight-bold text-center">0.00</h1>
          </CCol> -->
        </CRow>
        <h5 id="memberDetailPurchaseDetail" class="mt-4 text-center text-description" @click="getTransaction()">
          {{ $t('purchaseDetail') }} >
        </h5>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody v-if="frequencyPurchase.length > 0">
            <h4 id="memberDetailFrequentlyTitle">{{ $t('frequently') }}</h4>
            <CListGroup class="list-group-flush">
              <div v-for="(item, index) in frequencyPurchase" :key="index">
                <template v-if="index < 5">
                  <CListGroupItem class="pl-0 pr-0 border-bottom" :id="`memberDetailFrequentlyItem-${index}`">
                    <table style="width: 100%;">
                      <tr>
                        <td style="width:10%" valign="top">
                          <div
                            v-if="item.remoteImagePath"
                            class="square-box"
                            :style="{
                              'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                              'background-size': 'cover',
                              'background-position': 'center center',
                              width: '100%',
                            }"
                            :id="`memberDetailFrequentlyItemImage-${index}`"
                          ></div>
                          <div
                            v-else
                            class="square-box"
                            :style="{
                              backgroundColor: item.backgroundColor,
                              width: '100%',
                              'background-origin': 'content-box',
                            }"
                            :id="`memberDetailFrequentlyItemNoImage-${index}`"
                          ></div>
                        </td>
                        <td class="pl-2" valign="top">
                          <h5 class="text-dark" :id="`memberDetailFrequentlyItemName-${index}`">
                            {{ item.pluName }}
                          </h5>
                          <h6 class="text-muted text-dark" :id="`memberDetailFrequentlyItemCode-${index}`">
                            {{ item.pluCode }}
                          </h6>
                        </td>

                        <td style="width: 30%" class="text-right" valign="top">
                          <h6 class="text-dark text-right" :id="`memberDetailFrequentlyItemQuantity-${index}`">
                            <b>{{ item.quantity }} </b>
                          </h6>
                          <h6 class="text-muted" :id="`memberDetailFrequentlyItemAmount-${index}`">
                            {{ convertCurrency(item.netAmount) }}
                          </h6>
                        </td>
                      </tr>
                    </table>
                  </CListGroupItem>
                </template>
              </div>
            </CListGroup>
          </CCardBody>
          <CCardBody v-else>
            <h4 id="memberDetailFrequentlyTitleNoItems">{{ $t('frequently') }}</h4>
            <CJumbotron class="mt-3 mb-0" id="memberDetailFrequentlyNoItems">
              <h4 class="text-muted text-center text-dark">{{ $t('noItem')}}</h4>
            </CJumbotron>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard>
          <CCardBody v-if="lastPurchase.length > 0">
            <h4 id="memberDetailRecentPurchaseTitle">
              {{ $t('recentPurchase') }}
            </h4>
            <CListGroup class="list-group-flush">
              <div v-for="(item, index) in lastPurchase" :key="index">
                <template v-if="index < 5">
                  <CListGroupItem class="pl-0 pr-0 border-bottom" :id="`memberDetailRecentPurchaseItem-${index}`">
                    <table style="width: 100%;">
                      <tr>
                        <td valign="top">
                          <h5 class="text-dark" :id="`memberDetailRecentPurchaseReceipt-${index}`">
                            {{ item.receiptNumber }}
                          </h5>
                          <h6 class="text-muted text-dark" :id="`memberDetailRecentPurchaseDate-${index}`">
                            {{ showDatetime(item.created_at) }}
                          </h6>
                        </td>

                        <td style="width: 30%" class="text-right text-dark" valign="top">
                          <h6 class="text-muted" :id="`memberDetailRecentPurchaseAmount-${index}`">
                            {{ convertCurrency(item.grandTotal) }}
                          </h6>
                        </td>
                      </tr>
                    </table>
                  </CListGroupItem>
                </template>
              </div>
            </CListGroup>
          </CCardBody>
          <CCardBody v-else>
            <h4 class="font-weight-normal" id="memberDetailRecentPurchaseTitleNoItems">
              {{ $t('recentPurchase') }}
            </h4>
            <CJumbotron class="mt-3 mb-0" id="memberDetailRecentPurchaseNoItems">
              <h4 class="text-muted text-center text-dark">{{ $t('noItem')}}</h4>
            </CJumbotron>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <access-data id="memberDetailNoPermission"></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import moment from 'moment'
import util from '@/util/util'
import permis from '@/util/permission'

export default {
  data() {
    return {
      data: {},
      firstname: '',
      lastname: '',
      loadingButton: false,
      deletedAt: null,
      frequencyPurchase: [],
      lastPurchase: [],
      grandTotal: 0,
      bill: 0,
      details: [],
      imgSrc: '../../../img/line-icon.png',
      isMember: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    isPermission() {
      return permis.findPermissionRead('member', '/member/data')
    },
    isEditMember() {
      return permis.findPermissionEdit('member', '/member/data')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    objectId() {
      return this.$route.params.objectId
    },
    avgBill() {
      if (this.grandTotal) {
        let avg = this.grandTotal / this.bill
        return this.convertCurrency(avg)
      } else {
        return '0.00'
      }
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.getMemberByObjectId()
    this.getMemberDashboard()
  },
  methods: {
    ...util,
    showDatetime(datetime) {
      return moment(datetime).format('LLL')
    },
    catIcon(catName) {
      return catName.charAt(0).toUpperCase()
    },
    convertPoint(point) {
      if (point != undefined) {
        return util.convertNumber(point)
      } else {
        return 0
      }
    },
    convertCurrency(number) {
      return util.convertCurrency(number)
    },
    showDate(createdAt) {
      return moment(createdAt).format('DD/MM/YYYY HH:mm')
    },
    getMemberByObjectId() {
      const uid = this.uid
      const objectId = this.objectId
      const headers = { shopObjectId: this.shopObjectId }
      let url =
        '/api/v1.0/' + uid + '/Shop/getmemberbyobjectId/' + objectId + '/data'

      pos({
        method: 'get',
        url: url,
        headers: headers,
      })
        .then((res) => {
          let doc = res.data.data.documents

          if (doc) {
            this.data = doc
            this.firstname =  doc.name || `${doc.firstname || doc.firstName || ''} ${doc.lastname || doc.lastName || ''}`|| ''
            this.deletedAt = doc.deletedAt
            this.isMember = doc.isMember
          } else {
            console.log('error')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getMemberDashboard() {
      const uid = this.uid
      let params = {
        shopObjectId: this.shopObjectId,
        memberObjectId: this.objectId,
      }
      const headers = { shopObjectId: this.shopObjectId }

      pos({
        method: 'get',
        url: '/api/v1.0/' + uid + '/Receipt/memberdashboard/data',
        params: params,
        headers: headers,
      })
        .then((res) => {
          let purchase = res.data.data
          this.frequencyPurchase = purchase.frequencyPurchase
          this.lastPurchase = purchase.lastPurchase
          this.grandTotal = purchase.grandTotal
          this.bill = purchase.bill
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getTransaction() {
      this.$router.push('/member/' + this.objectId + '/gettransaction')
    },
    goback() {
      this.$router.push('/member/data')
    },
  },
}
</script>

<style>
.square-box {
  position: relative;
  width: 20%;
  overflow: hidden;
  border-radius: 5px;
}
.square-box:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.circle {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: #acd7c6;
  border-radius: 50%;
}
.circle h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #2f9576;
  font-size: 100px;
}
</style>
